<template>
  <b-form>
    <b-card title="Ekle">
      <b-form-group label="Başlık">
        <b-form-input
          v-model="education.title"
          placeholder="Başlık"
        />
      </b-form-group>
      <b-form-group
        label="Eğitim Tarihi"
        label-for="edate"
      >
        <b-form-datepicker
          id="edate"
          v-model="education.edate"
          v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
          locale="tr"
          start-weekday="1"
        />
      </b-form-group>
      <b-form-group label="Metin">
        <b-form-textarea
          v-model="education.content"
          placeholder="Metin"
        />
      </b-form-group>
    </b-card>
    <b-card title="Dosya">
      <b-form-group label="Dosya Yükle">
        <b-form-file
          v-model="education.upload_doc"
          placeholder="Bir dosya seçin veya buraya bırakın..."
          drop-placeholder="Dosyayı buraya bırakın..."
          browse-text="Dosya Seçin"
        />
      </b-form-group>
    </b-card>
    <b-card title="Galeri Fotoğrafları">
      <b-form-group label="Fotoğraf Yükle">
        <b-form-file
          v-model="education.upload_images"
          placeholder="Bir görsel seçin veya buraya bırakın..."
          drop-placeholder="Görseli buraya bırakın..."
          browse-text="Fotoğrafları Seçin"
          multiple
        />
      </b-form-group>
    </b-card>
    <b-button
      variant="danger"
      :disabled="submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </b-form>
</template>

<script>
import {
  BForm,
  BCard,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BFormGroup,
  BButton, BFormDatepicker,
} from 'bootstrap-vue'

export default {
  name: 'Add',
  components: {
    BForm,
    BCard,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BFormGroup,
    BFormDatepicker,
    BButton,
  },
  data() {
    return {
      submitStatus: false,
    }
  },
  computed: {
    education() {
      return this.$store.getters['educations/getEducation']
    },
    saveStatus() {
      return this.$store.getters['educations/getEducationSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.$store.dispatch('educations/educationReset')
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.education.submitStatus = true
      this.$store.dispatch('educations/educationSave', this.education)
    },
  },
}
</script>
